import React from "react"
import { DateTime } from "luxon"

import { LoginLayout } from "~/layouts/LoginLayout"

type MaintenanceNotificationProps = {
	expectedDate?: string
}
const MaintenanceNotification: React.FC<MaintenanceNotificationProps> =
	React.memo(({ expectedDate }) => {
		const date = expectedDate ? DateTime.fromISO(expectedDate) : undefined
		return (
			<LoginLayout>
				<div className="typography">
					<h1>Maintenance</h1>

					<p>
						The Hyla Service Center is currently under maintenance.
						<br />
						{date && (
							<>
								We expect the system to be available again at{" "}
								<time dateTime={date.toISO()}>
									{date
										.toLocal()
										.toLocaleString(DateTime.DATETIME_FULL)}
								</time>
							</>
						)}
						.
					</p>
				</div>
			</LoginLayout>
		)
	})

export default MaintenanceNotification
