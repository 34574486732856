import { lazyRouteComponent, Route } from "@tanstack/react-router"

import { authenticatedRoute } from "~/pages/authenticated/authenticatedRoute"
import { onboardingRouteCollection } from "~/pages/authenticated/onboarding/routes"

export const dashboardWrap = new Route({
	getParentRoute: () => authenticatedRoute,
	id: "dashboardWrap",
	component: lazyRouteComponent(() => import("~/layouts/Dashboard/DashboardLayout")),
})

export const dashboardRoute = new Route({
	getParentRoute: () => dashboardWrap,
	id: "dashboard",
	path: "/",
	component: lazyRouteComponent(() => import("./dashboard/Dashboard")),
})

export const profileRoute = new Route({
	getParentRoute: () => dashboardWrap,
	path: "profile",
})

export const addressRoute = new Route({
	getParentRoute: () => profileRoute,
	path: "address",
	component: lazyRouteComponent(() => import("./profile/Address")),
})

export const emailRoute = new Route({
	getParentRoute: () => profileRoute,
	path: "email",
	component: lazyRouteComponent(() => import("./profile/Email")),
})

export const deviceRoute = new Route({
	getParentRoute: () => dashboardWrap,
	path: "device/$id",
	component: lazyRouteComponent(() => import("./devices/DeviceLayout")),
})

export const deviceRenameRoute = new Route({
	getParentRoute: () => deviceRoute,
	path: "rename",
	component: lazyRouteComponent(() => import("./devices/Rename")),
})

export const deviceSupportRoute = new Route({
	getParentRoute: () => deviceRoute,
	path: "support",
	component: lazyRouteComponent(() => import("./devices/Support")),
})

export const deviceContactRoute = new Route({
	getParentRoute: () => deviceRoute,
	path: "contact",
	component: lazyRouteComponent(() => import("./devices/Contact")),
})

export const authenticatedRouteCollection = authenticatedRoute.addChildren([
	onboardingRouteCollection,
	dashboardWrap.addChildren([
		dashboardRoute,
		profileRoute.addChildren([addressRoute, emailRoute]),
		deviceRoute.addChildren([
			deviceRenameRoute,
			deviceSupportRoute,
			deviceContactRoute,
		]),
	]),
])
