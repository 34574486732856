import React from "react"

import Styles from "./Notifications.module.css"
import { NotificationTypes } from "./NotificationTypes"

import { AlertWithId } from "~/context/NotificationContext"

const TypeToStyle = {
	[NotificationTypes.Error]: Styles.error,
	[NotificationTypes.Info]: Styles.info,
}

type AlertProps = {
	alert: AlertWithId
	onDismiss: () => void
}
const Alert = React.memo<AlertProps>(({ alert, onDismiss }) => {
	const onClose = () => {
		if (alert.onDismiss) {
			alert.onDismiss()
		}

		onDismiss()
	}
	return (
		<div className={`${Styles.alert} ${TypeToStyle[alert.type] ?? ""}`}>
			<div className={Styles.headingRow}>
				<span className={Styles.heading}>{alert.title}</span>

				{alert.dismissable !== false && (
					<button className={Styles.dismiss} onClick={onClose}>
						<span className={Styles.buttonText}>Close</span>
					</button>
				)}
			</div>
			<span className={Styles.content}>{alert.content}</span>
		</div>
	)
})

type AlertsProps = {
	alerts: AlertWithId[]
	onDismiss: (id: string) => () => void
}
const Notifications: React.FC<AlertsProps> = ({ alerts, onDismiss }) => {
	return (
		<div className={Styles.alerts}>
			{alerts.map((alert) => {
				return (
					<Alert
						alert={alert}
						key={alert.id}
						onDismiss={onDismiss(alert.id)}
					/>
				)
			})}
		</div>
	)
}

export default Notifications
