import { DateTime } from "luxon"

import { captureSentryException } from "~/common/helper/sentry"
import { useMaintenanceStore } from "~/context/MaintenanceContext"

export const handleFetch = async <T, E>(
	callable: () => Promise<T>,
	errorReturn: E
) => {
	try {
		return await callable()
	} catch (e) {
		captureSentryException(e)

		return {
			status: errorReturn,
		} as T
	}
}

export const callApi: typeof fetch = async (...args) => {
	try {
		const response = await fetch(...args)

		if (response.status === 503) {
			if (
				!response.headers
					.get("Content-Type")
					?.includes("application/json")
			) {
				return response
			}

			try {
				const data = await response.json()

				if (data.status === "maintenance" && data.start && data.end) {
					useMaintenanceStore
						.getState()
						.setMaintenance(
							DateTime.fromISO(data.start),
							DateTime.fromISO(data.end)
						)
				}
			} catch (e) {}
		}

		return response
	} catch (e: any) {
		throw e
	}
}
