import { lazyRouteComponent, Route } from "@tanstack/react-router"

import { authenticatedRoute } from "~/pages/authenticated/authenticatedRoute"
import { apolloClient } from "~/apollo/client"
import { CreateDeviceDocument } from "~/generated/graphql"
import {
	CreateDeviceMutation,
	CreateDeviceMutationVariables,
} from "~/generated/graphql-operations"

const onboardingWrap = new Route({
	getParentRoute: () => authenticatedRoute,
	id: "onboarding-wrap",
	path: "o",
})

export const onboardingRoute = new Route({
	getParentRoute: () => onboardingWrap,
	id: "onboarding",
	path: "$serial",
	beforeLoad: async ({ params: { serial } }) => {
		await apolloClient.mutate<
			CreateDeviceMutation,
			CreateDeviceMutationVariables
		>({
			mutation: CreateDeviceDocument,
			variables: {
				serial: serial,
			},
		})
	},
	component: lazyRouteComponent(() => import("./OnboardingController")),
})

export const thankYouRoute = new Route({
	getParentRoute: () => onboardingRoute,
	path: "/",
	component: lazyRouteComponent(() => import("./ThankYou")),
})

export const privacyRoute = new Route({
	getParentRoute: () => onboardingRoute,
	path: "privacy",
	component: lazyRouteComponent(() => import("./Privacy/Privacy")),
})

export const addressRoute = new Route({
	getParentRoute: () => onboardingRoute,
	path: "address",
	component: lazyRouteComponent(() => import("./Address/Address")),
})

export const usageRoute = new Route({
	getParentRoute: () => onboardingRoute,
	path: "usage",
	component: lazyRouteComponent(() => import("./Usage/Usage")),
})

const summaryWrap = new Route({
	getParentRoute: () => onboardingRoute,
	path: "summary",
})

export const summaryRoute = new Route({
	getParentRoute: () => summaryWrap,
	path: "/",
	component: lazyRouteComponent(() => import("./Summary/Summary")),
})

export const addressSummaryRoute = new Route({
	getParentRoute: () => summaryWrap,
	path: "address",
	component: lazyRouteComponent(() => import("./Address/Address")),
})

export const usageSummaryRoute = new Route({
	getParentRoute: () => summaryWrap,
	path: "usage",
	component: lazyRouteComponent(() => import("./Usage/Usage")),
})

export const registrationDoneRoute = new Route({
	getParentRoute: () => onboardingWrap,
	path: "registrationDone/$serial",
	component: lazyRouteComponent(() => import("./Done/Done")),
})

export const onboardingRouteCollection = onboardingWrap.addChildren([
	onboardingRoute.addChildren([
		thankYouRoute,
		privacyRoute,
		addressRoute,
		usageRoute,
		summaryWrap.addChildren([
			summaryRoute,
			addressSummaryRoute,
			usageSummaryRoute,
		]),
	]),
	registrationDoneRoute,
])
