import { DateTime } from "luxon"
import { FieldFunctionOptions, FieldReadFunction } from "@apollo/client/cache"

import { currentLanguage } from "~/i18n"

const checkField = (data: any | undefined, options: FieldFunctionOptions) => {
	if (data) {
		return true
	}

	if (import.meta.env.DEV) {
		console.warn(
			`DateTime value in field "${options.fieldName}" is undefined. Please use "readOptionalDateTime" instead.`,
			data,
			options
		)
	}

	return false
}

export const readDateTime: FieldReadFunction<string, DateTime> = (
	iso,
	options
) => {
	if (!checkField(iso, options) || !iso) {
		return undefined
	}

	return DateTime.fromISO(iso).setLocale(currentLanguage()).toLocal()
}

export const readDateTimeUTC: FieldReadFunction<string, DateTime> = (
	iso,
	options
) => {
	if (!checkField(iso, options) || !iso) {
		return undefined
	}

	return DateTime.fromISO(iso).setLocale(currentLanguage()).toUTC()
}

export const readDateTimeArray: FieldReadFunction<string[], DateTime[]> = (
	isos,
	options
) => {
	if (!checkField(isos, options) || !isos) {
		return undefined
	}

	return isos.map((iso) =>
		DateTime.fromISO(iso).setLocale(currentLanguage()).toLocal()
	)
}

export const readOptionalDateTime: FieldReadFunction<
	string | undefined | null,
	DateTime | undefined | null
> = (iso: string | undefined | null) => {
	if (iso === null) {
		return null
	}

	if (iso === undefined) {
		return undefined
	}

	return DateTime.fromISO(iso).setLocale(currentLanguage()).toLocal()
}
