import React, { useContext } from "react"
import PortalComp from "@reach/portal"

import { PortalContext } from "~/context/PortalContext"

type PortalProps = {
	usePortal?: boolean
	preventSubmit?: boolean
	children?: React.ReactNode
}
const Portal: React.FC<PortalProps> = ({
	usePortal = true,
	preventSubmit = true,
	children,
}) => {
	const portalContext = useContext(PortalContext)

	if (!usePortal) {
		return <>{children}</>
	}

	if (preventSubmit) {
		return (
			<PortalComp containerRef={portalContext ?? undefined}>
				<div
					onSubmit={(e) => {
						e.stopPropagation()
						e.nativeEvent.stopPropagation()
					}}
				>
					{children}
				</div>
			</PortalComp>
		)
	}

	return (
		<PortalComp containerRef={portalContext ?? undefined}>
			{children}
		</PortalComp>
	)
}

export default Portal
