import { lazyRouteComponent, Route } from "@tanstack/react-router"

import { publicRoute } from "../routes"

const _authRoute = new Route({
	getParentRoute: () => publicRoute,
	id: "authentication",
})

const loginRoute = new Route({
	getParentRoute: () => _authRoute,
	path: "login",
})

export const loginPageRoute = new Route({
	getParentRoute: () => loginRoute,
	path: "/",
	component: lazyRouteComponent(() => import("./Login/Login")),
})

export const authenticateRoute = new Route({
	getParentRoute: () => loginRoute,
	path: "$token",
	component: lazyRouteComponent(() => import("./Authenticate/Authenticate")),
})

export const registerRoute = new Route({
	getParentRoute: () => _authRoute,
	path: "r/$serial",
	component: lazyRouteComponent(() => import("./Register/Register")),
})

export const authRoute = _authRoute.addChildren([
	loginRoute.addChildren([loginPageRoute, authenticateRoute]),
	registerRoute,
])
