import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client"
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries"

import { typePolicies } from "./type-policies"
import sentryLink from "./helpers/sentryLink"
import { apolloUsePreGeneratedHashes } from "./helpers/apolloUsePreGeneratedHashes"

import generatedIntrospection from "~/generated/fragment-matcher"
import { API_ENDPOINT } from "~/common/declarations"

const httpLink = new HttpLink({
	uri: `${API_ENDPOINT}/__graphql`,
	credentials: "include",
})

const persistedLink = createPersistedQueryLink({
	generateHash: apolloUsePreGeneratedHashes(() =>
		import("~/generated/persisted-client.json").then(
			(query) => query.default
		)
	),
})

export const apolloClient = new ApolloClient({
	cache: new InMemoryCache({
		typePolicies,
		possibleTypes: generatedIntrospection.possibleTypes,
	}),
	link: from(
		import.meta.env.DEV ? [httpLink] : [sentryLink, persistedLink, httpLink]
	),
})
