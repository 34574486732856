import React from "react"

import { useCreateMaintenanceContext } from "./MaintenanceContext"

import MaintenanceNotification from "~/components/MaintenanceNotification/MaintenanceNotification"

const MaintenanceContextProvider: React.FC<{
	children?: React.ReactNode
}> = ({ children }) => {
	const ctx = useCreateMaintenanceContext()

	if (ctx.maintenance) {
		return (
			<>
				<MaintenanceNotification expectedDate={ctx.maintenanceEnd} />
			</>
		)
	}

	return <>{children}</>
}

export default MaintenanceContextProvider
