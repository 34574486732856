import React from "react"
import { RouterProvider } from "@tanstack/react-router"

import Styles from "./App.module.css"
import MaintenanceContextProvider from "./context/MaintenanceContextProvider"
import NotificationContextProvider from "./context/NotificationContextProvider"

import { router } from "~/common/routes"
import LanguageSelector from "~/pages/authenticated/components/LanguageSelector"

const App: React.FC = () => {
	return (
		<div className={Styles.bg}>
			<div className={Styles.inner}>
				<LanguageSelector />
				<NotificationContextProvider>
					<MaintenanceContextProvider>
						<RouterProvider router={router} />
					</MaintenanceContextProvider>
				</NotificationContextProvider>
			</div>
		</div>
	)
}

export default App
