import { lazyRouteComponent, Route } from "@tanstack/react-router"

import { rootRoute } from "~/common/rootRoute"

export const authenticatedRoute = new Route({
	id: "authenticated",
	component: lazyRouteComponent(() => import("./components/Authenticated")),
	errorComponent: lazyRouteComponent(() => import("./components/AuthenticationError")),
	getParentRoute: () => rootRoute,
})
