import { StrictTypePolicies } from "./type-policy-type-helper"

import { AllTypesMap } from "~/generated/graphql-types"
import {
	readDateTime,
	readOptionalDateTime,
} from "~/apollo/helpers/readDateTime"
import { offsetLimitPagination } from "~/apollo/helpers/offsetLimitPagination"

export const typePolicies: StrictTypePolicies<AllTypesMap> = {
	Mutation: {},
	Classification: {},
	Device: {},
	Devicetype: {},
	User: {},
	UserProfile: {},
	UsersDevice: {
		fields: {
			purchase_date: readOptionalDateTime,
			warrantyUntil: readOptionalDateTime,
		},
	},
	PorpaginasResult_UsersDevice: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	Address: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	ContactInfo: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
}
