import React from "react"
import { Outlet } from "@tanstack/react-router"

export const LoginLayout: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	return <>{children}</>
}

const RouteLoginLayout: React.FC = () => {
	return (
		<LoginLayout>
			<Outlet />
		</LoginLayout>
	)
}

export default RouteLoginLayout
