import { z } from "zod"

import { callApi, handleFetch } from "./helper"

import { API_ENDPOINT } from "~/common/declarations"

export const enum LoginResult {
	ERROR,
	INVALID,
	SUCCESS,
	PRODUCT_REGISTRATION,
}

const registerResponse = z.object({
	register: z.string(),
})

const _login = async (token: string) => {
	const formData = new FormData()
	formData.set("token", token)
	formData.set("remember_me", "on")

	try {
		const login = await callApi(`${API_ENDPOINT}/users/code-login`, {
			method: "post",
			body: formData,
			credentials: "include",
			headers: {
				accept: "application/json",
			},
		})

		if (login.status === 401) {
			return { status: LoginResult.INVALID }
		}

		if (login.status === 200) {
			try {
				const data = await login.json()
				const { register } = await registerResponse.parseAsync(data)

				return {
					status: LoginResult.PRODUCT_REGISTRATION,
					serial: register,
				}
			} catch (e) {}

			return { status: LoginResult.SUCCESS }
		}

		if (login.status !== 204) {
			return { status: LoginResult.ERROR }
		}

		return { status: LoginResult.SUCCESS }
	} catch (e) {
		return { status: LoginResult.ERROR }
	}
}

export const login = (token: string) => {
	return handleFetch(() => _login(token), { status: LoginResult.ERROR })
}
