import { Route, lazyRouteComponent } from "@tanstack/react-router"

import { publicRoute } from "../routes"

const _infoRoute = new Route({
	getParentRoute: () => publicRoute,
	path: "info",
	component: lazyRouteComponent(() => import("~/layouts/TutorialLayout")),
})

const estRoute = new Route({
	getParentRoute: () => _infoRoute,
	path: "est",
	component: lazyRouteComponent(() => import("./models/InfoEst")),
})

export const infoRoute = _infoRoute.addChildren([estRoute])
