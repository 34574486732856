import { DateTime } from "luxon"

import { callApi, handleFetch } from "./helper"

import { API_ENDPOINT } from "~/common/declarations"

export const enum CheckAuthResult {
	ERROR,
	MAINTENANCE,
	AUTHENTICATED,
	UNAUTHENTICATED,
}
const _checkAuth = async () => {
	const response = await callApi(`${API_ENDPOINT}/users/check`, {
		credentials: "include",
		headers: {
			accept: "application/json",
		},
	})

	if (response.status === 503) {
		const date = await response.text()

		return {
			status: CheckAuthResult.MAINTENANCE,
			date: date.length > 0 ? DateTime.fromISO(date) : undefined,
		}
	}

	if (response.status !== 200) {
		return { status: CheckAuthResult.ERROR }
	}

	const { authenticated } = await response.json()

	return {
		status: authenticated
			? CheckAuthResult.AUTHENTICATED
			: CheckAuthResult.UNAUTHENTICATED,
	}
}

export const checkAuth = async () => {
	return handleFetch(_checkAuth, CheckAuthResult.ERROR)
}
