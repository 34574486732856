import React, { useEffect, useMemo, useState } from "react"
import { DateTime } from "luxon"
import { create } from "zustand"

import { useNotificationContext } from "./NotificationContext"

import { API_ENDPOINT } from "~/common/declarations"
import { NotificationTypes } from "~/components/Notifications/NotificationTypes"
import { callApi } from "~/api/helper"
import { relativeDuration } from "~/common/helper/relativeDuration"

const getNextMaintenance = async (): Promise<
	[DateTime, DateTime] | undefined | null
> => {
	try {
		const response = await callApi(`${API_ENDPOINT}/maintenance/next`, {
			credentials: "include",
		})

		if (response.status === 503) {
			return null
		}

		if (response.status !== 200) {
			return undefined
		}

		const data = await response.json()

		return [
			DateTime.fromISO(data.start).toLocal(),
			DateTime.fromISO(data.end).toLocal(),
		]
	} catch (e) {
		return undefined
	}
}

type MaintenanceStore = {
	maintenanceStart: string | undefined
	maintenanceEnd: string | undefined
	setMaintenance: (start?: DateTime, end?: DateTime) => void
}

export const useMaintenanceStore = create<MaintenanceStore>((set) => ({
	maintenanceStart: undefined,
	maintenanceEnd: undefined,
	setMaintenance: (start, end) =>
		set({
			maintenanceStart: start?.toISO(),
			maintenanceEnd: end?.toISO(),
		}),
}))

export const useCreateMaintenanceContext = () => {
	const { addAlert, removeAlert } = useNotificationContext()
	const { maintenanceStart, maintenanceEnd, setMaintenance } =
		useMaintenanceStore()
	const [time, setTime] = useState<string | undefined>(undefined)

	useEffect(() => {
		if (!maintenanceStart) {
			return
		}

		setTime(DateTime.now().toISO())
		const interval = setInterval(
			() => setTime(DateTime.now().toISO()),
			3000
		)

		return () => {
			clearInterval(interval)
		}
	}, [maintenanceStart])

	const maintenance = useMemo(() => {
		if (!maintenanceStart) {
			return false
		}

		const localTime = time ? DateTime.fromISO(time) : DateTime.now()
		const startTime = DateTime.fromISO(maintenanceStart)
		const endTime = maintenanceEnd
			? DateTime.fromISO(maintenanceEnd)
			: undefined

		return startTime <= localTime && (!endTime || endTime >= localTime)
	}, [maintenanceStart, maintenanceEnd, time])

	useEffect(() => {
		const alertMaintenance = async () => {
			const date = await getNextMaintenance()

			if (date === null) {
				return
			}

			if (date === undefined) {
				setMaintenance(undefined, undefined)
				return
			}

			setMaintenance(date[0], date[1])
		}

		alertMaintenance().then()

		const interval = setInterval(
			alertMaintenance,
			maintenance ? 10000 : 5 * 60 * 1000
		)

		return () => {
			clearInterval(interval)
		}
	}, [setMaintenance, maintenance])

	useEffect(() => {
		if (!maintenance) {
			return
		}

		removeAlert("maintenance")
	}, [maintenance, removeAlert])

	useEffect(() => {
		if (!maintenanceStart) {
			removeAlert("maintenance")

			return
		}

		const date = DateTime.fromISO(maintenanceStart)

		if (
			date <= DateTime.now() ||
			date >= DateTime.now().plus({ hour: 2 })
		) {
			removeAlert("maintenance")

			return
		}

		const duration = date.diffNow()
		const durationText = relativeDuration(duration, {
			significantUnits: 2,
			units: ["hours", "minutes"],
		})

		addAlert({
			id: "maintenance",
			type: NotificationTypes.Info,
			title: "Planned Maintenance",
			content: (
				<>
					System Maintenance will take place in {durationText} (
					{date.toLocal().toLocaleString(DateTime.DATETIME_SHORT)}).
					<br />
					Please save your application before then to avoid data loss.
				</>
			),
		})
	}, [maintenanceStart, addAlert, removeAlert])

	return {
		maintenance,
		maintenanceStart,
		maintenanceEnd,
		setMaintenance,
	}
}
