import { createContext, ReactNode, useContext } from "react"

import { NotificationTypes } from "~/components/Notifications/NotificationTypes"

export interface AlertInterface {
	id?: string
	type: NotificationTypes
	title: string
	content: ReactNode
	dismissable?: boolean
	onDismiss?: () => void
}

interface AlertContextInterface {
	addAlert: (alert: AlertInterface) => void
	removeAlert: (id: string) => void
}

export interface AlertWithId extends AlertInterface {
	id: string
}

export const NotificationContext = createContext<AlertContextInterface | null>(
	null
)

export const useNotificationContext = () => {
	const ctx = useContext(NotificationContext)

	if (ctx === null) {
		throw new Error("No AlertContextProvider available.")
	}

	return ctx
}
