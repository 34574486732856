import React from "react"

import Styles from "./LoadingScreen.module.css"

import Logo from "~/assets/logo.svg?component"
import LoadingIndicator from "~/components/LoadingIndicator/LoadingIndicator"

type LoadingScreenProps = {}
const LoadingScreen: React.FC<LoadingScreenProps> = () => {
	return (
		<div className={Styles.wrap}>
			<div className={Styles.logoWrap}>
				<Logo className={Styles.logo} />
			</div>
			<div className={Styles.indicator}>
				<LoadingIndicator />
			</div>
		</div>
	)
}

export default LoadingScreen
