import { ApolloLink } from "@apollo/client/link/core/ApolloLink"
import { Operation } from "@apollo/client"
import { OperationDefinitionNode } from "graphql"

import { addSentryBreadcrumb } from "~/common/helper/sentry"

const operationInfo = (operation: Operation) => {
	const type = operation.query.definitions.find(
		(defn) => defn.kind === "OperationDefinition"
	) as OperationDefinitionNode

	return {
		type: type.operation,
		name: operation.operationName,
		fragments: operation.query.definitions
			.map<string | undefined>((defn) =>
				defn.kind === "FragmentDefinition" ? defn.name.value : undefined
			)
			.filter(Boolean)
			.join(", "),
		variables: operation.variables,
	}
}

const sentryLink = new ApolloLink((operation, forward) => {
	addSentryBreadcrumb({
		category: "graphql",
		data: operationInfo(operation),
		level: "debug",
	})

	if (import.meta.env.DEV && import.meta.env.VITE_SHOW_GRAPHQL_INFO) {
		console.table(operationInfo(operation))
	}

	return forward(operation)
})

export default sentryLink
