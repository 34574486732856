import { Router } from "@tanstack/react-router"

import { rootRoute } from "~/common/rootRoute"
import { confirmEmail, publicRoute } from "~/pages/public/routes"
import { authRoute } from "~/pages/public/auth/routes"
import { infoRoute } from "~/pages/public/info/routes"
import { authenticatedRouteCollection } from "~/pages/authenticated/routes"
import LoadingScreen from "~/components/LoadingScreen/LoadingScreen"

const routeTree = rootRoute.addChildren([
	authenticatedRouteCollection,
	publicRoute.addChildren([authRoute, infoRoute, confirmEmail]),
])

export const router = new Router({
	routeTree: routeTree,
	defaultPendingComponent: LoadingScreen,
})

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router
	}
}
