import { Duration, DurationObjectUnits } from "luxon"

interface Opts {
	// Default: 2
	significantUnits?: number

	// Default: all but quarters, months & milliseconds
	units?: (keyof DurationObjectUnits)[]

	justNowDuration?: Duration
}

const allUnits: ReadonlyArray<keyof DurationObjectUnits> = [
	"years",
	"quarters",
	"months",
	"weeks",
	"days",
	"hours",
	"minutes",
	"seconds",
	"milliseconds",
]
// No quarters, weeks or milliseconds:
const defaultUnits: ReadonlyArray<keyof DurationObjectUnits> = [
	"years",
	"months",
	"days",
	"hours",
	"minutes",
	"seconds",
]

// https://github.com/moment/luxon/issues/1129#issuecomment-1100979588
export function relativeDuration(duration: Duration, opts?: Opts): string {
	let sigU = opts?.significantUnits ?? 2
	if (sigU < 1) {
		throw Error("Significant units can't be < 1")
	}

	if (
		opts?.justNowDuration &&
		opts?.justNowDuration.toMillis() >= Math.abs(duration.toMillis())
	) {
		return "a few moments"
	}

	let units = opts?.units ?? defaultUnits
	// Make sure units are ordered in descending significance:
	units = allUnits.filter((it) => units.includes(it))

	let negative = duration.valueOf() < 0
	if (negative) {
		duration = duration.negate()
	}
	duration = duration.shiftTo(...units)

	// Remove unnecessary most-significant units:
	while (units.length > 1) {
		if (duration.get(units[0]) > 0) {
			// we've found the most significant unit:
			break
		}

		units = units.slice(1)
		duration = duration.shiftTo(...units)
	}

	units = units.slice(0, sigU)
	duration = duration.shiftTo(...units)
	// If the last unit has fractional bits, we don't care. We're explicitly limiting significant units:
	let lastUnit = units[units.length - 1]
	duration = duration.set({
		[lastUnit]: Math.floor(duration.get(lastUnit)),
	})

	return duration.toHuman()
}
