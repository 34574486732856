import React, { ChangeEventHandler, useCallback } from "react"
import { useTranslation } from "react-i18next"

import { languages } from "~/i18n"

const RenderLanguageSelector: React.FC = () => {
	const [, { language, changeLanguage }] = useTranslation()

	const setLang = useCallback<ChangeEventHandler<HTMLSelectElement>>(
		(event) => {
			changeLanguage(event.currentTarget.value)
		},
		[changeLanguage]
	)

	return (
		<div className="fixed bottom-4 right-4 text-black text-xs">
			<select value={language} onChange={setLang}>
				{languages.map((lang) => (
					<option key={`lang-${lang}`} value={lang}>
						{lang}
					</option>
				))}
			</select>
		</div>
	)
}

const LanguageSelector: React.FC = () => {
	return (
		<React.Suspense>
			<RenderLanguageSelector />
		</React.Suspense>
	)
}

export default LanguageSelector
