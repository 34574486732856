import React from "react"
import ReactDOM from "react-dom/client"
import { Settings } from "luxon"

import "./tailwind.css"
import "./main.css"

import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/700.css"

import App from "./App"
import { initSentry } from "./common/helper/sentry"
import i18n from "./i18n"

initSentry()
Settings.defaultLocale = "en"
Settings.throwOnInvalid = true

i18n.on("languageChanged", (lng) => {
	Settings.defaultLocale = lng
})

declare module "luxon" {
	interface TSSettings {
		throwOnInvalid: true
	}
}

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
