import React, { useCallback, useState } from "react"
import uniqueId from "lodash/uniqueId"

import {
	NotificationContext,
	AlertInterface,
	AlertWithId,
} from "./NotificationContext"

import Notifications from "~/components/Notifications/Notifications"
import Portal from "~/components/Portal/Portal"

const NotificationContextProvider: React.FC<{
	children?: React.ReactNode
}> = ({ children }) => {
	const [alerts, setNotifications] = useState<AlertWithId[]>([])

	const removeAlert = useCallback(
		(id: string) => () => {
			setNotifications((alerts) => alerts.filter((a) => a.id !== id))
		},
		[]
	)

	const addAlert = useCallback((alert: AlertInterface) => {
		const id = alert.id ?? uniqueId("alert-")

		setNotifications((alerts) => {
			if (!alert.id) {
				return [
					...alerts,
					{
						id: id,
						...alert,
					},
				]
			}

			return [
				...alerts.filter((a) => a.id !== alert.id),
				{
					id: alert.id,
					...alert,
				},
			]
		})

		return id
	}, [])

	const ctx = {
		addAlert,
		removeAlert,
	}

	return (
		<NotificationContext.Provider value={ctx}>
			{alerts.length > 0 && (
				<Portal>
					<Notifications alerts={alerts} onDismiss={removeAlert} />
				</Portal>
			)}
			{children}
		</NotificationContext.Provider>
	)
}

export default NotificationContextProvider
