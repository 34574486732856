import { callApi } from "./helper"

import { API_ENDPOINT } from "~/common/declarations"
import { captureSentryException } from "~/common/helper/sentry"

export const logout = async () => {
	try {
		await callApi(`${API_ENDPOINT}/users/logout`, {
			credentials: "include",
			headers: {
				accept: "application/json",
			},
		})
	} catch (e) {
		captureSentryException(e)
	}
}
