import React from "react"

import Styles from "./LoadingIndicator.module.css"

import { classNames } from "~/common/helper/classNames"

type LoadingIndicatorProps = {
	active?: boolean
}
const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
	active = true,
}) => {
	return (
		<div
			className={classNames(Styles.indicator, active && Styles.active)}
		/>
	)
}

export default LoadingIndicator

export const MediumLoadingIndicator: React.FC<
	React.PropsWithChildren<
		LoadingIndicatorProps & {
			className?: string
		}
	>
> = ({ className = "", children, ...props }) => {
	return (
		<div className="text-center">
			<div className={`h-8 w-8 m-auto ${className}`}>
				<LoadingIndicator {...props} />
			</div>
			{children && <p className="mt-4">{children}</p>}
		</div>
	)
}
