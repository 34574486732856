import i18n, { ResourceKey } from "i18next"
import { initReactI18next } from "react-i18next"
import resourcesToBackend from "i18next-resources-to-backend"
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector"
import { DateTime } from "luxon"
import { makeVar } from "@apollo/client"

import common from "./locales/en/common.json"
import register from "./locales/en/register.json"
import onboarding from "./locales/en/onboarding.json"
import dashboard from "./locales/en/dashboard.json"
import login from "./locales/en/login.json"
import profile from "./locales/en/profile.json"
import countries from "./locales/en/countries.json"
import landing from "./locales/en/landing.json"
import device from "./locales/en/device.json"

export const currentLanguage = makeVar("en")

export const defaultNS = "common"
export const resources = {
	en: {
		common,
		register,
		login,
		profile,
		onboarding,
		dashboard,
		countries,
		landing,
		device,
	},
} as const

const translations = Object.entries(import.meta.glob("~/locales/*/*.json"))
	.map(([key, value]) => {
		const [lang, ns] = key.split("/").slice(-2)
		return [lang, ns.replace(".json", ""), value] as const
	})
	.reduce((acc, [lang, ns, resolver]) => {
		if (!(lang in acc)) {
			acc[lang] = {}
		}

		acc[lang][ns] = resolver

		return acc
	}, {} as any) as Record<
	string,
	Record<keyof (typeof resources)["en"], () => Promise<ResourceKey>>
>

export const languages = Object.keys(translations)

i18n.use(initReactI18next)
	.use(I18nextBrowserLanguageDetector)
	.use(
		resourcesToBackend((language, namespace, cb) => {
			const lang = translations[language]
			if (lang && namespace in lang) {
				lang[namespace as keyof typeof lang]()
					.then((d) => cb(null, d))
					.catch((e) => cb(e, null))

				return
			}

			cb("Not found", null)
		})
	)
	.init({
		fallbackLng: "en",
		supportedLngs: languages,
		defaultNS,
		resources,
		partialBundledLanguages: true,

		debug: import.meta.env.DEV,

		interpolation: {
			escapeValue: false,
		},
	})

i18n.on("languageChanged", (lng) => currentLanguage(lng))

i18n.services.formatter!.add("DATE_SHORT", (value, lng, options) => {
	return value.setLocale(lng ?? "en").toLocaleString(DateTime.DATE_SHORT)
})

i18n.services.formatter!.add("DATE_MED", (value, lng, options) => {
	return value.setLocale(lng ?? "en").toLocaleString(DateTime.DATE_MED)
})

i18n.services.formatter!.add("DATE_MED_WITH_WEEKDAY", (value, lng, options) => {
	return value
		.setLocale(lng ?? "en")
		.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
})

i18n.services.formatter!.add("DATE_FULL", (value, lng, options) => {
	return value.setLocale(lng ?? "en").toLocaleString(DateTime.DATE_FULL)
})

i18n.services.formatter!.add("DATETIME_SHORT", (value, lng, options) => {
	return value.setLocale(lng ?? "en").toLocaleString(DateTime.DATETIME_SHORT)
})

i18n.services.formatter!.add("DATETIME_MED", (value, lng, options) => {
	return value.setLocale(lng ?? "en").toLocaleString(DateTime.DATETIME_MED)
})

i18n.services.formatter!.add(
	"DATETIME_MED_WITH_WEEKDAY",
	(value, lng, options) => {
		return value
			.setLocale(lng ?? "en")
			.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
	}
)

i18n.services.formatter!.add("DATETIME_FULL", (value, lng, options) => {
	return value.setLocale(lng ?? "en").toLocaleString(DateTime.DATETIME_FULL)
})

export default i18n
