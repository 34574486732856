import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Outlet, RouteMatch, useNavigate, useRouter } from "@tanstack/react-router"

import { AuthenticationContext } from "./AuthenticationContext"

import { apolloClient } from "~/apollo/client"
import { checkAuth, CheckAuthResult } from "~/api/checkAuth"
import { login, LoginResult } from "~/api/login"
import { logout as logoutApi } from "~/api/logout"
import LoadingScreen from "~/components/LoadingScreen/LoadingScreen"
import { router } from "~/common/routes"
import { onboardingRoute } from "~/pages/authenticated/onboarding/routes"
import { publicRoute } from "~/pages/public/routes"
import { authRoute } from "~/pages/public/auth/routes"
import { dashboardRoute } from "~/pages/authenticated/routes"

type LoginResultObj = Awaited<ReturnType<typeof login>>
const AuthenticationContextProvider: React.FC = () => {
	const [authenticationStatus, setAuthenticationStatus] = useState<
		| {
				auth: CheckAuthResult | undefined
				login?: LoginResultObj | undefined
		  }
		| undefined
	>(undefined)

	const validatePromise = useRef<Promise<void>>()
	const path = useRef<string>()

	const navigate = useNavigate()
	const {
		state: {
			location: { pathname: currentPath },
			matches,
		},
	} = useRouter()

	const isAuthRoute = useMemo(() => {
		return matches.some((m) => m.id.startsWith(authRoute.id))
	}, [matches])

	const logout = useCallback(async () => {
		if (!authenticationStatus) {
			return
		}

		await logoutApi()
		await navigate({ to: "/" })
		await apolloClient.clearStore()

		setAuthenticationStatus(undefined)
	}, [authenticationStatus, navigate])

	const fetchAuthenticated = useCallback(
		async (loginResult?: LoginResultObj) => {
			const response = await checkAuth()

			setAuthenticationStatus({
				auth: response.status,
				login: loginResult,
			})
		},
		[]
	)

	const authenticate = useCallback(
		async (token: string) => {
			const result = await login(token)

			if (result.status === LoginResult.INVALID) {
				throw new Error(
					"Your login link has expired. You will shortly receive a new link."
				)
			}

			if (result.status === LoginResult.ERROR) {
				throw new Error(
					"Something went wrong. Please ensure that you have copied the entire login link."
				)
			}

			await fetchAuthenticated(result)
		},
		[fetchAuthenticated]
	)

	useEffect(() => {
		if (authenticationStatus) {
			return
		}

		;(async () => {
			const promise = (async () => {
				try {
					await fetchAuthenticated()
				} catch (ignore) {}
			})()

			validatePromise.current = promise

			await promise

			if (validatePromise.current === promise) {
				validatePromise.current = undefined
			}
		})()
	}, [fetchAuthenticated, authenticationStatus])

	useEffect(() => {
		if (authenticationStatus?.auth === CheckAuthResult.AUTHENTICATED) {
			if (!isAuthRoute) {
				return
			}

			const { login } = authenticationStatus

			if (login && login.status === LoginResult.PRODUCT_REGISTRATION) {
				navigate({
					to: "/o/$serial",
					params: {
						serial: login.serial as string,
					},
				})

				return
			}

			navigate({
				to: path.current ?? dashboardRoute.path,
				replace: true,
				search: {} as any,
				params: {} as any,
			})

			return
		}

		const isPublic = router.state.matchIds[router.state.matchIds.length - 1].startsWith(publicRoute.id)

		if (authenticationStatus === undefined || isPublic) {
			return
		}

		path.current = currentPath

		const onboardingMatch = router.state.matches.find(
			(m) => m.id === onboardingRoute.id
		) as RouteMatch<any, typeof onboardingRoute> | undefined
		if (onboardingMatch) {
			navigate({
				to: "/r/$serial",
				replace: true,
				search: {},
				params: {
					serial: onboardingMatch.params.serial,
				},
			})

			return
		}

		navigate({
			to: "/login",
			replace: true,
			search: {},
			params: {},
		})
	}, [authenticationStatus, currentPath, navigate, isAuthRoute])

	const authenticated = useMemo(() => {
		if (authenticationStatus === undefined) {
			return undefined
		}

		return authenticationStatus.auth === CheckAuthResult.AUTHENTICATED
	}, [authenticationStatus])

	const ctx = {
		authenticated,
		authenticationStatus: authenticationStatus?.auth,
		logout,
		authenticate,
		validatePromise,
	}

	return (
		<AuthenticationContext.Provider value={ctx}>
			{ctx.authenticationStatus === undefined && <LoadingScreen />}
			{ctx.authenticationStatus !== undefined && <Outlet />}
		</AuthenticationContext.Provider>
	)
}

export default AuthenticationContextProvider
