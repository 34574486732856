import { lazyRouteComponent, Route } from "@tanstack/react-router"

import { rootRoute } from "~/common/rootRoute"

export const publicRoute = new Route({
	id: "publicRoutes",
	getParentRoute: () => rootRoute,
})

export const confirmEmail = new Route({
	getParentRoute: () => publicRoute,
	path: "confirm-email/$token",
	component: lazyRouteComponent(() => import("~/pages/public/ConfirmEmail/ConfirmEmail")),
})
